import Collapsible from '../Collapsible';

import picure1 from '../../assets/images/MeasurmentProcedure/Picture1.png';
import picure2 from '../../assets/images/MeasurmentProcedure/Picture2.png';
import picure3 from '../../assets/images/MeasurmentProcedure/Picture3.png';
import picure4 from '../../assets/images/MeasurmentProcedure/Picture4.png';
import proceduraPomiaru from '../../assets/images/MeasurmentProcedure/proceduraPomiaru.jpg';
import raportDekra from '../../assets/files/RaportDekra.pdf';
import './index.scss';
import scrollMeTo from '../../helpers/scrollMeTo';

export const QandA = () => {
  return (
    // @ts-ignore
    <div className='q-and-a' name='questionsAndAnswers'>
      <div className='container'>
        <div className='title q-and-a__title'>PYTANIA I ODPOWIEDZI</div>
        <div className='q-and-a__question-section'>
          <Collapsible title='Co to jest test DEKRA baterii pojazdów elektrycznych/hybrydowych?'>
            Test DEKRA baterii jest prostą i szybką (ok. 15 min.) metodą
            pozwalającą miarodajnie określić kondycję baterii wyliczając tzw.
            Wskaźnik Pozostałej Pojemności (SoH) w oparciu o pomiar statyczny
            oraz dynamiczną próbę przyspieszenia. Od pojemności baterii zależy
            zasięg pojazdu oraz jego wartość rynkowa.
            <br />
            Wynikiem testu DEKRA baterii jest raport PDF zawierający określenie
            Wskaźnika Pozostałej Pojemności (SoH).
          </Collapsible>
          <Collapsible title='Jak przebiega pomiar kondycji baterii'>
            <div>
              <div className='q-and-a__procedures'>
                <p className='procedures-title'>Procedura pomiaru</p>
                <img
                  className='procedures-image'
                  src={proceduraPomiaru}
                  alt='procedura pomiaru'
                />
                <div style={{ display: 'flex' }}>
                  <div className='procedures-item'>
                    Połączenie testera DEKRA z portem OBDI pojazdu oraz
                    uruchomienie aplikacji DEKRA zainstalowanej na smartfonie
                    eksperta DEKRA
                  </div>
                  <div className='procedures-item'>
                    Odczyt danych diagnostycznych oraz weryfikacja gotowości
                    pojazdu do testu dynamicznego
                  </div>
                  <div className='procedures-item'>
                    Próba przyspieszenia na odcinku ok. 100 m celem pobrania
                    danych do wyliczenia parametru SoH
                  </div>
                  <div className='procedures-item'>
                    Bezpośrednio po zakończeniu próby przyspieszenia na ekranie
                    smartfona eksperta DEKRA generowany jest wynik badania,
                    który przesyłany jest również w formacie PDF na email
                    klienta
                  </div>
                </div>
              </div>
              {
                'Warunki niezbędne do przeprowadzenia testu DEKRA baterii: stan naładowania baterii od 40% do 80%, temperatura ogniw od +10°C do +30°C, odchylenie temperatur ogniw <2°C, brak krytycznych błędów baterii - sprawdzane podczas testu statycznego.Do próby przyspieszenia potrzebny jest ok. 100 metrowy odcinek utwardzonej płaskiej powierzchni, na której można bezpiecznie rozpędzić pojazd do prędkości ok. 50 km/h. Nawierzchnia nie jest śliska (np. oblodzenie) i nie występują intensywne opady deszczu, śniegu, etc.'
              }
            </div>
          </Collapsible>
          <Collapsible title='Czy można sprawdzić w DEKRA stan baterii każdego samochodu?'>
            Test DEKRA baterii nie obejmuje jeszcze wszystkich pojazdów, gdyż
            dla każdego modelu i pojemności baterii niezbędne jest
            przeprowadzenie osobnej procedury parametryzacji w DEKRA Technology
            Center. Lista możliwych do sprawdzenia pojazdów znajduje się na{' '}
            <a href='https://test-baterii.dekra.pl/'>
              {' www.test-baterii.dekra.pl'}
            </a>{' '}
            i jest stale uzupełniana.
          </Collapsible>
          <Collapsible title='Jak wygląda przykładowy raport z testu baterii DEKRA'>
            <a  target="_blank" href={raportDekra}>
              <span style={{ textDecoration: 'underline' }}>
                Raport z testu DEKRA
              </span>
            </a>{' '}
            jest dostarczany w formie pliku pdf i zawiera obliczony Wskaźnik
            Pozostałej Pojemności (SoH), pojemność nominalną baterii trakcyjnej
            oraz napięcie i temperaturę ogniw, a także dane identyfikacyjne
            pojazdu: markę, model, numer identyfikacyjny pojazdu, datę 1.
            rejestracji oraz przebieg. Raport jest dostępny bezpośrednio po
            dokonaniu pomiarów.
          </Collapsible>
          <Collapsible title='Jaka jest cena testu DEKRA baterii?'>
            Test DEKRA baterii kosztuje 489 zł (brutto). Kwota obejmuje
            przeprowadzenie testu DEKRA baterii, raport PDF z badania oraz koszt
            dojazdu.
          </Collapsible>
          <button
            className='button'
            onClick={() => {
              scrollMeTo('selector');
            }}
          >
            Zamów test baterii
          </button>
        </div>
      </div>
    </div>
  );
};

export default QandA;
