import React from 'react';
import './index.scss';

import poland from '../../assets/icons/poland.png';
import europe from '../../assets/icons/europe.png';
import eRaport from '../../assets/icons/eRaport.png';

const UseExpertsServices = () => {
  return (
    <div className='use-expert-services'>
      <div className='container'>
        <h2 className='use-expert-services__header title'>
          skorzystaj z usług ekspertów{' '}
          <span className='use-expert-services__header--green'>dekra</span>
        </h2>
        <div className='use-expert-services__wrapper items'>
          <div className='item'>
            <img src={europe} alt='europe' className='item__img' />
            <p className='item__text'>
              Największa organizacja ekspertów w Europie założona w 1925 r.
            </p>
          </div>
          <div className='item'>
            <img src={poland} alt='poland' className='item__img' />
            <div className='item__text'>
              <p>Pierwszy dostawca szybkiego testu baterii w Polsce</p>
            </div>
          </div>
          <div className='item'>
            <img src={eRaport} alt='eRaport' className='item__img' />
            <p className='item__text'>
              Wynik testu baterii jest dostępny od ręki
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseExpertsServices;
