import React, { useState } from 'react';
import './index.scss';
import logoHeader from '../../assets/icons/logo-header.svg';
import scrollMeTo from '../../helpers/scrollMeTo';
import { isMobile } from 'react-device-detect';

const NavBar = () => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <div className='navbar'>
      <div className='navbar__container container'>
        <a href='/' className='navbar__logo-link'>
          <img src={logoHeader} alt='Dekra' />
        </a>

        <nav
          className={`navbar__menu ${isMenuOpen ? 'navbar__menu--open' : ''}`}
        >
          <ul className='navbar__menu-container'>
            <li className={`navbar__menu-item`}>
              <div
                className='navbar__menu-item-content'
                onClick={() => {
                  scrollMeTo('howItWorks');
                }}
              >
                Jak to działa
              </div>
            </li>
            <li className={`navbar__menu-item`}>
              <div
                className='navbar__menu-item-content'
                onClick={() => {
                  scrollMeTo('questionsAndAnswers');
                }}
              >
                Pytania i odpowiedzi
              </div>
            </li>
          </ul>
        </nav>
        <button
          className='navbar__order-button'
          title='Zamów'
          onClick={() => {
            scrollMeTo(isMobile ? 'order-pricing-buttons' : 'selector');
          }}
        >
          Zamów test baterii
        </button>

        <div
          className='navbar__hamburger-icon'
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
