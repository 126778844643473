import React, { useState, useContext } from 'react';
import './index.scss';
import qr_code from '../../assets/icons/qr_code.png';
import form from '../../assets/icons/form.png';

import { isMobile } from "react-device-detect";
import { decodeDataService } from '../../api';
import { QrCodeDataContext } from '../../providers/QrCodeDataProvider';
import scrollMeTo from '../../helpers/scrollMeTo';
import QRscanner from '../QRscanner';

interface decodedDataInterface {
  Make: string;
  Vin: string;
  FirstRegistrationDate: string;
  RegistrationNumber: string;
  Name: string;
  Surname: string;
  Adress: string;
  City: string;
  PostalCode: string;
  VehicleCategory: string;
  Fuel: string;
}

const OrderPricingButtons = () => {
  const [showScanner, setShowScanner] = useState(false);
  const [error, setError] = useState('');
  const qrCodeContext = useContext(QrCodeDataContext);

  const decodeData = async (decodedCode: string) => {
    setShowScanner(false);

    await decodeDataService(decodedCode)
      .then((res) => {
        setError('');
        qrCodeContext.updateData(res.data.Data);
        scrollMeTo('selector');
      })
      .catch((err) => {
        setError('Nie udało się zeskanować zdjęcia. Wpisz dane do formularza');
      })
      .finally(() => {});
  };

  return (
    <div className='order-pricing-buttons'>
      {showScanner && (
        <QRscanner
          closeScanner={() => setShowScanner(false)}
          uncodedData={(decodedCode: string) => decodeData(decodedCode)}
        />
      )}
      <div className='container'>
        <div className='order-pricing-buttons__wrapper'>
          {isMobile && (
            <div className='button-container'>
              <div
                className='order-pricing-buttons--button button'
                onClick={() => {
                  setShowScanner(true);
                }}
              >
                Zamów test baterii
              </div>
              <div className='subtitle'>
                <img src={qr_code} alt='qr code' className='subtitle__icon' />
                <p className='subtitle__text'>
                  Mogę zeskanowac kod QR z dowodu rejestracyjnego
                </p>
              </div>
            </div>
          )}
          <div className='button-container'>
            <div
              className='order-pricing-buttons--button button'
              onClick={() => {
                scrollMeTo('selector');
              }}
            >
              Zamów test baterii
            </div>
            <div className='subtitle'>
              <img src={form} alt='qr code' className='subtitle__icon' />
              <p
                className='subtitle__text'
              >
                Wpiszę dane do formularza
              </p>
            </div>
          </div>
        </div>
        {error && (
          <>
            <div className='uploading__fail'>{error}</div>
          </>
        )}
        <div
          className='order-pricing-buttons__bottom-text'
        >
          <span>Uwaga: usługa dostępna na terenie miast: Gdańsk/Gdynia/Sopot, Poznań, Radom, Warszawa, Wrocław</span>
          <br />
          <br />
          <span>Chciałbyś przeprowadzić test baterii dla większej liczby pojazdów lub innym mieście?</span>
          <br />
          <span>Skontaktuj się z nami:</span> &nbsp;
          <a
            href='mailto:ekspertyza.pl@dekra.com'
            className='bottom-text__mail'
          >
            ekspertyza.pl@dekra.com
          </a>
          , &nbsp;
          <a href='tel:+48608661454' className='bottom-text__phone'>
            608-661-454
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderPricingButtons;
