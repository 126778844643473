import React from 'react';
import './index.scss'

import logoFooter from '../../assets/icons/logo-footer.svg';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__content'>
          <div className='footer__left-section'>
          <img src={logoFooter} alt='Dekra' className='footer__logo'/>
            <a href='https://production.dekra.pl/pl/ochrona-danych/'>
              Polityka prywatności
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
