import { useState } from 'react';
import './index.scss';

import NavBar from '../../components/NavBar';
import UseExpertsServices from '../../components/UseExpertsServices';
import HowItWork from '../../components/HowItWorks';
import Footer from '../../components/Footer';
import QandA from '../../components/QandA';
import Header from '../../components/Header';
import Selector from '../../components/Selector';
import PricingForm from '../../components/PricingForm';
import RequestInformation from '../../components/RequestInformation';
import OrderPricingButtons from '../../components/OrderPricingButtons';

const MainPage = () => {
  const [displayDataSet, setDataSet] = useState<object>({});
  const [vehicleDataSet, setVehicleDataSet] = useState<object>();
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [paymentUrl,setPaymentUrl] = useState<string>()
  const [codeValue, setCodeValue] = useState('');
  const [promoCodeStatus, setPromoCodeStatus] = useState();

  const resetResState = () => {
    setIsSent(false);
    setIsError(false);
    setIsSuccess(false);
  };

  return (
    <div className='main-page'>
      <NavBar />
      <Header />
      <UseExpertsServices />
      <OrderPricingButtons/>
      <HowItWork />
      <QandA />
      {!isSent && (
        <>
          <Selector
            handleDisplayDataSet={setDataSet}
            handleVehicleDataSet={setVehicleDataSet}
            loadingHandler={setIsLoading}
            loadingState={isLoading}
            setCodeValue={setCodeValue}
            setPromoCodeStatus={setPromoCodeStatus}
          />
          <PricingForm
            batteryData={displayDataSet}
            providedVehicle={vehicleDataSet}
            errorHandler={setIsError}
            successHandler={setIsSuccess}
            sendHandler={setIsSent}
            loadingHandler={setIsLoading}
            loadingState={isLoading}
            handlePaymentUrl = {setPaymentUrl}
            codeValue={codeValue}
            setCodeValue={setCodeValue}
            promoCodeStatus={promoCodeStatus}
            setPromoCodeStatus={setPromoCodeStatus}
          />
        </>
      )}
      {isSent && (
        <RequestInformation
          isError={isError}
          isSuccess={isSuccess}
          closeHandler={resetResState}
          paymentUrl={paymentUrl}
        />
      )}
      <Footer />
    </div>
  );
};

export default MainPage;
