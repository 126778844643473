import React, { useState, useEffect, useContext } from 'react';
import SelectorList from '../SelectorList';

import { QrCodeDataContext } from '../../providers/QrCodeDataProvider';
import { vehicleDataInterface } from '../../common/interfaces';
import { getVehiclesData } from '../../api';
import './index.scss';
import Loader from '../Loader';

interface selectorInterface {
  handleDisplayDataSet: (dataSet: object) => void;
  handleVehicleDataSet: (vehicleData: object) => void;
  loadingHandler: Function;
  loadingState: boolean;
  setCodeValue: any;
  setPromoCodeStatus: any;
}

const Selector: React.FC<selectorInterface> = ({
  handleDisplayDataSet,
  handleVehicleDataSet,
  loadingHandler,
  loadingState,
  setCodeValue,
  setPromoCodeStatus
}) => {
  const [availableVehicle, setAvailableVehicle] = useState<[]>();
  const [makesList, setMakesList] = useState<string[]>();
  const [availableModels, setAvailableModels] = useState<string[]>([]);
  const [availableBattery, setAvailableBattery] = useState<string[]>([]);
  const [selectedMake, setSelectedMake] = useState<string>();
  const [selectedModel, setSelectedModel] = useState<string>();
  const [selectedBattery, setSelectedBattery] = useState<string>();
  const qrCodeContext = useContext<any>(QrCodeDataContext);

  const selectedDataSet = {
    Make: selectedMake,
    Model: selectedModel,
    Battery: selectedBattery
  };

  useEffect(() => {
    loadingHandler(true);
    loadVehicles();
  }, []);

  useEffect(() => {
    if (qrCodeContext.data.Make) {
      setSelectedMake(qrCodeContext.data.Make);
    }
  }, [qrCodeContext]);

  useEffect(() => {
    selectedMake && availableVehicle && setAvailableModels(filterModels);
    setAvailableBattery([]);
    setSelectedBattery('');
    setSelectedModel('');
  }, [selectedMake]);
  
  useEffect(() => {
    selectedMake &&
    selectedModel &&
    availableVehicle &&
    setAvailableBattery(filterBattery);
    setSelectedBattery('');
  }, [selectedModel]);

  const filterModels: any = availableVehicle
    ?.filter((vehicle: vehicleDataInterface) => {
      return vehicle.Make.trim() === selectedMake;
    })
    .map((vehicle) => {
      return vehicle['Model'];
    })
    .sort((a: string, b: string) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

  const filterBattery: any = availableVehicle
    ?.filter((vehicleModel) => {
      return vehicleModel['Model'] === selectedModel;
    })
    .map((vehicleModel) => {
      return vehicleModel['Capacity'];
    });

  const loadVehicles = async () => {
    try {
      const vehicleData = await getVehiclesData();
      const loadedMakes: string[] = [];
      vehicleData.data.forEach(
        (vehicle: vehicleDataInterface, index: number) => {
          if (!loadedMakes.includes(vehicle.Make.trim())) {
            loadedMakes.push(vehicle.Make.trim());
          }
        }
      );
      setMakesList(loadedMakes.sort());
      setAvailableVehicle(vehicleData.data);
    } catch (error) {
    } finally {
      loadingHandler(false);
    }
  };

  useEffect(() => {
    const selectedVehicle: any = availableVehicle?.find((el) => {
      return (
        el['Make'] === selectedMake &&
        el['Model'] === selectedModel &&
        el['Capacity'] === selectedBattery
      );
    });
    handleVehicleDataSet(selectedVehicle);
  }, [selectedBattery]);

  useEffect(() => {
    handleDisplayDataSet(selectedDataSet);
    setCodeValue('');
    setPromoCodeStatus();
  }, [selectedMake, selectedModel, selectedBattery]);

  return loadingState ? (
    <Loader />
  ) : (
    // @ts-ignore
    <div className='selector' name='selector'>
      <div className='selector__container'>
        {/* @ts-ignore */}
        <h2 className='title'>zamów test baterii</h2>
        <SelectorList
          dataSet={makesList}
          selectorHandler={setSelectedMake}
          selectedName={selectedMake}
          scrollTo={'model-list'}
        />
        {selectedMake && availableModels && (
          <>
            <h2 className='selector__label model-list'>
              Wybierz model pojazdu
              <br />
              <span className='selector__label__selected-value'>{selectedMake}</span>
            </h2>
            <SelectorList
              dataSet={availableModels}
              selectorHandler={setSelectedModel}
              selectedName={selectedModel}
              scrollTo={'battery-list'}
            />
          </>
        )}
        {selectedMake && selectedModel && availableBattery && (
          <>
            <h2 className='selector__label battery-list'>
              Wybierz pojemność baterii
              <br />
              <span className='selector__label__selected-value'>{`${selectedMake} ${selectedModel}`}</span>
            </h2>
            <SelectorList
              dataSet={availableBattery}
              selectorHandler={setSelectedBattery}
              selectedName={selectedBattery}
              scrollTo={'pricing-form__selected-data'}
              additionalUnit={'kWh'}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Selector;
