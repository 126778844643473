import lock from '../../assets/icons/lock.svg';
import './index.scss';

interface requestInformationInterface {
  isError: boolean;
  isSuccess: boolean;
  closeHandler: Function;
  paymentUrl?:string
}
export const RequestInformation: React.FC<requestInformationInterface> = ({
  isError,
  isSuccess,
  closeHandler,
  paymentUrl
}) => {
  return (
    <div className='response-info'>
      <div className='response-info__main'>
        {isError ? (
          <div className={`response-info__box error`}>
            <h2>coś poszło nie tak</h2>
            <p>wystąpił błąd przy wysyłaniu formularza</p>
            <button
              className='button'
              onClick={() => {
                closeHandler();
              }}
            >
              Spróbuj ponownie
            </button>
          </div>
        ) : isSuccess ? (
          <div className={`response-info__box success`}>
            <h2>Dziękujemy za złożenie zapytania</h2>
            <a href={paymentUrl} target='_blank'>
              <button
                className='button'
                onClick={() => {
                  closeHandler();
                }}
              >
                Przejdź do płatności
              </button>
            </a>
            <div className='lock-info'>
              <img
                className='lock-info__img'
                src={lock}
                alt='Bezpieczna płatność. Link do płatności otrzymasz mailem'
              />
              <div>Bezpieczna płatność.</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RequestInformation;
