import { scroller } from "react-scroll"

const scrollMeTo=(scrollElement)=>{
  scroller.scrollTo(scrollElement, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart'
  })
}

export default scrollMeTo;