import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export const getVehiclesData = async () => {
  return await axios.get(`/api/VehicleInfo`);
};

export const sendOrderData = async (orderObj: object) => {
  return await axios({
    method: 'POST',
    url: '/api/Order',
    data: orderObj,
    validateStatus: () => true
  });
};

export const decodeDataService = async (code: any) => {
  return await axios({
    method: 'POST',
    data: {qrCodeString: code},
    url: `/api/Decode`,
  });
};

export const checkPromoCode = async (promoCode: string) => {
  return await axios({
    method: 'GET',
    url: `/api/PromotionalCode?promotionalCode=${promoCode}`,
    validateStatus: () => true
  });
};