import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Textfield from '../Textfield';
import './index.scss';
import { checkPromoCode, sendOrderData } from '../../api';
import Loader from '../Loader';
import { QrCodeDataContext } from '../../providers/QrCodeDataProvider';
import regulations from '../../assets/files/regulamin-BT.pdf';
import informationClause from '../../assets/files/Klauzula-Informacyjna-Test-Baterii-FINAL.pdf';
import OtpInput from 'react-otp-input';
interface PricingFormInterface {
  children?: React.ReactNode;
  batteryData?: any;
  providedVehicle?: any;
  errorHandler: Function;
  successHandler: Function;
  sendHandler: Function;
  loadingHandler: Function;
  loadingState: boolean;
  handlePaymentUrl: Function;
  codeValue?: any;
  setCodeValue?: any;
  promoCodeStatus?: any;
  setPromoCodeStatus?: any;
}

export const PricingForm: React.FC<PricingFormInterface> = ({
  batteryData,
  providedVehicle,
  errorHandler,
  successHandler,
  sendHandler,
  loadingHandler,
  loadingState,
  handlePaymentUrl,
  codeValue,
  setCodeValue,
  promoCodeStatus,
  setPromoCodeStatus
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm();

  const qrCodeContext = useContext<any>(QrCodeDataContext);
  const [validCodeValue, setValidCodeValue] = useState('');
  const [newPrice, setNewPrice] = useState(0);
  useEffect(() => {
    if (qrCodeContext.data) {
      const dataFromQr = {
        Make: qrCodeContext.data.Make,
        VIN: qrCodeContext.data.Vin,
        FirstRegDate: qrCodeContext.data.FirstRegistrationDate,
        RegNumber: qrCodeContext.data.RegistrationNumber,
        Name: qrCodeContext.data.Name,
        Surname: qrCodeContext.data.Surname,
        InspectionAddress: qrCodeContext.data.Adress,
        InspectionAddressCity: qrCodeContext.data.City,
        InspectionAddressPostalCode: qrCodeContext.data.PostalCode
      };
      reset(dataFromQr);
    }
  }, [qrCodeContext]);

  const handleCheckPromoCode = () => {
    checkPromoCode(codeValue).then((res) => {
      setValidCodeValue('');
      setNewPrice(0);
      setPromoCodeStatus(res.data.ValidationResult);

      if (res.data.ValidationResult === 0) {

        setValidCodeValue(codeValue);
        setNewPrice(res.data.Price);
      }
    });
  };

  const submit = handleSubmit(async (data, e) => {
    try {
      loadingHandler(true);
      await sendOrderData({
        VehicleCategoryId: '1',
        Model: providedVehicle.Model.trim(),
        Capacity: providedVehicle.Capacity,
        PromotionalCode: validCodeValue,
        ...data
      }).then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          successHandler(true);
          errorHandler(false);
          sendHandler(true);
          handlePaymentUrl(res.data.PaymentUrl);
          return;
        }
        errorHandler(true);
        successHandler(false);
        sendHandler(true);
      });
    } catch (error) {
      errorHandler(true);
      loadingHandler(false);
    } finally {
      loadingHandler(false);
    }

    reset();
  });

  const regExpPhone = /^(([\+]?[0-9]{11})|([0-9]{9}))$/gm;
  const regExpEmail = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi;
  const regExpPostalCode = /^[0-9]{2}[-\s]*?[0-9]{3}$/;
  const regExpNIP = /^[0-9]{10}$/;
  const regExpVIN = /^[A-HJ-NPR-Za-hj-npr-z0-9]+$/; // W0L0TGF4835167621
  const regExpRegNumber = /^([0-9a-zA-Z\s]+)$/; // GSP 85MW

  return (
    <>
      {loadingState && <Loader />}
      {batteryData.Battery && Object.keys(batteryData.Battery).length !== 0 && (
        <>
          <form className='pricing-form' onSubmit={submit}>
            <p className='title'>Zamów test baterii</p>
            <p className='pricing-form__subtitle'>489 zł (brutto)</p>
            <div className='container'>
              <div className='pricing-form__header'>
                <div className='pricing-form__selected-data'>
                  <div className='pricing-form__detail'>
                    {batteryData?.Make}
                  </div>
                  <div className='pricing-form__detail'>
                    {batteryData?.Model}
                  </div>
                  <div className='pricing-form__detail'>
                    {batteryData?.Model && batteryData?.Battery + ' kWh'}
                  </div>
                </div>
                <div className='pricing-form__promo-code'>
                  <div className='pricing-form__promo-code-top'>
                    <label className={'textfield'}>
                      <span className='textfield__label'>
                        {'Kod promocyjny'}
                      </span>
                      <input
                        type={'text'}
                        className={`textfield__input`}
                        value={codeValue}
                        onChange={(e) => setCodeValue(e.target.value)}
                      />
                    </label>
                    <p className='promo-button' onClick={handleCheckPromoCode}>
                      Dodaj/Zrealizuj
                    </p>
                  </div>
                  <div className='pricing-form__promo-code-bottom'>
                    {promoCodeStatus === 1 || promoCodeStatus === 2 ? (
                      <div className='promo-code-bottom'>
                        <span className='promo-code-bottom__negative'>
                          Podany kod jest niepoprawny
                        </span>
                      </div>
                    ) : promoCodeStatus === 3 ? (
                      <div className='promo-code-bottom'>
                        <span className='promo-code-bottom__negative'>
                          Kod promocyjny został już wykorzystany maksymalną
                          liczbę razy
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {validCodeValue && newPrice && (
                      <div className='promo-code-bottom'>
                        <span className='promo-code-bottom__positive'>
                          Cena usługi po uwzględnieniu kodu promocyjnego wynosi
                        </span>
                        <span className='promo-code-bottom__positive promo-code-bottom__positive--price'>
                          {' '}
                          {newPrice} zł
                        </span>
                        <span className='promo-code-bottom__positive'>
                          {' '}(brutto)
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {/* not sure if this won't come back */}
                {/* <div className='pricing-form__cost-container'>
                  Przeprowadzenie testu baterii na podstawie próby drogowej oraz
                  sporządzenie raportu PDF
                </div> */}
              </div>
              <div className='pricing-form__label'>Dane kontaktowe:</div>
              <div className='pricing-form__group'>
                <Textfield
                  label='Imię'
                  register={register}
                  inputName='Name'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                />
                <Textfield
                  label='Nazwisko '
                  register={register}
                  inputName='Surname'
                  errors={errors}
                  type={'text'}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                />
                <Textfield
                  label='Nazwa firmy'
                  register={register}
                  inputName='OwnerCompany'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: false
                  }}
                />
                <Textfield
                  label='Telefon komórkowy'
                  register={register}
                  inputName='ContactPhone'
                  type={'tel'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' },
                    pattern: {
                      value: regExpPhone,
                      message: 'Podaj poprawny telefon'
                    }
                  }}
                />
                <Textfield
                  label='NIP'
                  register={register}
                  inputName='OwnerNip'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: false,
                    pattern: { value: regExpNIP, message: 'Podaj poprawny NIP' }
                  }}
                />
                <Textfield
                  label='Email'
                  register={register}
                  inputName='OwnerEmail'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' },
                    pattern: {
                      value: regExpEmail,
                      message: 'Podaj poprawny e-mail'
                    }
                  }}
                />
              </div>
              <div className='pricing-form__label'>Dane obiektu:</div>
              <div className='pricing-form__group'>
                <Textfield
                  label='Marka'
                  register={register}
                  inputName='Make'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                  inputValue={providedVehicle.Make}
                />
                <Textfield
                  label='Nr rejestracyjny'
                  register={register}
                  inputName='RegNumber'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' },
                    pattern: {
                      value: regExpRegNumber,
                      message: 'Podaj porawny numer rejestracyjny'
                    }
                  }}
                />
                <div className='input-boxes'>
                  <p>VIN*</p>
                  <Controller
                    control={control}
                    name='VIN'
                    rules={{
                      required: { value: true, message: 'Wymagane' },
                      pattern: {
                        value: regExpVIN,
                        message: 'Podaj porawny numer rejestracyjny'
                      },
                      minLength: {
                        value: 17,
                        message: 'Wymagane minimum 17 znaków'
                      }
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <OtpInput
                          onChange={onChange}
                          value={value}
                          numInputs={17}
                          renderInput={(props) => <input {...props} />}
                          containerStyle={'input-boxes__container'}
                          inputStyle={`${errors.VIN ? 'item error' : 'item'}`}
                        />
                      );
                    }}
                  />
                  {/* @ts-ignore */}
                  {errors.VIN && <p className='error'>{errors.VIN.message}</p>}
                </div>
                <Textfield
                  label='Data pierwszej rejestracji'
                  register={register}
                  inputName='FirstRegDate'
                  type={'date'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                />
              </div>
              <div className='pricing-form__label'>Adres oględzin:</div>
              <div className='pricing-form__group'>
                <Textfield
                  label='Miasto'
                  register={register}
                  inputName='InspectionAddressCity'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                />
                <Textfield
                  label='Kod pocztowy'
                  register={register}
                  inputName='InspectionAddressPostalCode'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' },
                    pattern: {
                      value: regExpPostalCode,
                      message: 'Podaj poprawny kod pocztowy'
                    }
                  }}
                />
                <Textfield
                  label='Ulica/nr'
                  register={register}
                  inputName='InspectionAddress'
                  type={'text'}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                />
              </div>

              <div className='pricing-form__info-group'>
                <p className='info-group__title'>Uwaga: usługa dostępna na terenie miast: Gdańsk/Gdynia/Sopot, Poznań, Radom, Warszawa, Wrocław</p>
                <span>Chciałbyś przeprowadzić test baterii dla większej liczby pojazdów lub innym mieście?</span>
                <br />
                <span>Skontaktuj się z nami:</span> &nbsp;
                <a
                  href='mailto:ekspertyza.pl@dekra.com'
                  className='info-group__mail'
                >
                  ekspertyza.pl@dekra.com
                </a>
                , &nbsp;
                <a href='tel:+48608661454' className='info-group__phone'>
                  608-661-454
                </a>
              </div>
              <div className='pricing-form__checkbox-group'>
                <Textfield
                  register={register}
                  checkboxText={`Oświadczam, że zapoznałam/em się z treścią <a target="_blank" href=${informationClause}>Klauzuli informacyjnej</a> dotyczącej przetwarzania danych osobowych`}
                  type={'checkbox'}
                  inputName='ConsentToProcessingPersonalData'
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                />
                <Textfield
                  register={register}
                  inputName='AcceptanceOfRegulations'
                  type={'checkbox'}
                  checkboxText={`Oświadczam, że zapoznałam/em się i akceptuję <a target="_blank" href=${regulations}>Regulamin usługi</a>`}
                  errors={errors}
                  validation={{
                    required: { value: true, message: 'Wymagane' }
                  }}
                />
                <Textfield
                  register={register}
                  checkboxText={
                    'Oświadczam, że jestem Konsumentem w rozumieniu art. 22(1) kodeksu cywilnego, tj. osobą fizyczną dokonującą z Przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową'
                  }
                  type={'checkbox'}
                  inputName='ConsentConsumer'
                  errors={errors}
                  validation={{
                    required: false
                  }}
                />
                <Textfield
                  register={register}
                  checkboxText={
                    'Żądam niezwłocznego rozpoczęcia wykonywania Usługi, przed upływem terminu do odstąpienia od umowy. Akceptuję, że zgodnie z art. 38 pkt 1 ustawy o prawach konsumenta z dnia 30 maja 2014 r. (Dz.U. z 2014 r. poz. 827), w przypadku zgłoszenia tego żądania, po wykonaniu w pełni Usługi nie przysługuje mi prawo odstąpienia od umowy na podstawie tej ustawy (dotyczy Konsumentów)'
                  }
                  type={'checkbox'}
                  inputName='StartServiceImmediatly'
                  errors={errors}
                  validation={{
                    required: false
                  }}
                />
                <Textfield
                  register={register}
                  checkboxText={
                    'Wyrażam zgodę na otrzymywanie od DEKRA Polska sp. z o.o. informacji handlowo-marketingowych za pośrednictwem poczty e-mail'
                  }
                  type={'checkbox'}
                  inputName='ConsentToEmailMarketingNotification'
                  errors={errors}
                  validation={{
                    required: false
                  }}
                />
              </div>
              <div className='pricing-form__cta'>
                <button className='pricing-form__submit'>
                  ZAMÓW TEST BATERII
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default PricingForm;
