let body = document.getElementsByTagName('body')[0];

export const blockScroll = () => {
  if (body) {
    body.classList.add('block-scroll');
  }
};

export const unblockScroll = () => {
  if (body) {
    body.classList.remove('block-scroll');
  }
};
