import React from 'react';
import './index.scss';

import icon1 from '../../assets/icons/howItWork/step1.svg';
import icon2 from '../../assets/icons/howItWork/step3.png';
import icon3 from '../../assets/icons/howItWork/step4.png';
import icon4 from '../../assets/icons/howItWork/step2.svg';
import icon5 from '../../assets/icons/howItWork/step5.svg';
import scrollMeTo from '../../helpers/scrollMeTo';

const HowItWork = () => {
  const steps = [
    {
      image: icon1,
      title: 'Chcesz poznać stan baterii pojazdu?',
      alt: 'Dekra'
    },
    {
      image: icon2,
      title: 'Wypełniasz formularz na stronie i przechodzisz do płatności',
      alt: 'Dekra'
    },
    {
      image: icon3,
      title: 'Dostaniesz od nas mail</br>z potwierdzeniem zamówienia',
      alt: 'Dekra'
    },
    {
      image: icon4,
      title: 'Wykonujemy w 15 min. test statyczny </br> oraz dynamiczny na odcinku ok. 100 m',
      alt: 'Dekra'
    },
    {
      image: icon5,
      title: 'Raport PDF dostępny natychmiast po badaniu',
      alt: 'Dekra'
    }
  ];

  return (
    // @ts-ignore
    <div className='how-it-work' name='howItWorks'>
      <div className='container'>
        <h2 className='how-it-work__header title'>Jak to działa?</h2>
        <div className='how-it-work__wrapper steps'>
          {steps.map((step, index) => (
            <div className='step' key={index}>
              <div className='step__round'>
                <div className='step__round__number'>{index + 1}</div>
                <img src={step.image} alt={step.alt} />
                <div className='step__round__triple' />
              </div>
              <p
                className='step__title'
                dangerouslySetInnerHTML={{ __html: step.title }}
              ></p>
            </div>
          ))}
        </div>
        <div
          className='button button--primary how-it-work__button'
          onClick={() => {
            scrollMeTo('selector');
          }}
        >
          Zamów test baterii
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
