import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';

import MainPage from './pages/MainPage';
import Page404 from './pages/Page404';
import QrCodeDataProvider from './providers/QrCodeDataProvider';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <QrCodeDataProvider>
          <Routes>
            <Route path='' element={<MainPage />} />
            <Route path='*' element={<Page404 />} />
          </Routes>
        </QrCodeDataProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
