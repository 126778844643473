import './index.scss';
import scrollMeTo from '../../helpers/scrollMeTo';

export const SelectorList: React.FC<{
  dataSet?: string[];
  selectorHandler: Function;
  selectedName?: string;
  scrollTo?: string;
  additionalUnit?: string;
}> = (props) => {
  return (
    // @ts-ignore
    <div className='selectorList container'>
      <ul className='selectorList__container'>
        {props.dataSet &&
          props.dataSet
            .filter((el, idx) => {
              return props.dataSet?.indexOf(el) === idx;
            })
            .map((el, idx) => {
              return (
                <li
                  key={idx} // temporary
                  className={`selectorList__item
                 ${el === props.selectedName ? 'selected' : ''}
                `}
                  onClick={() => {
                    props.selectorHandler(el);
                    scrollMeTo(props.scrollTo);
                  }}
                >
                  {props.additionalUnit ? `${el} ${props.additionalUnit}` : el}
                </li>
              );
            })}
      </ul>
    </div>
  );
};

export default SelectorList;
