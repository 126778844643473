import React, { useEffect, useState } from 'react';

import { UseFormRegister } from 'react-hook-form';
import './index.scss';

interface textfieldInterface {
  label?: string;
  type: string;
  inputName: string;
  register: UseFormRegister<any>;
  errors?: any;
  validation: any;
  checkboxText?: string;
  inputValue?: string;
}

export const Textfield: React.FC<textfieldInterface> = ({
  label,
  type,
  inputName,
  register,
  errors,
  validation,
  checkboxText,
  inputValue
}) => {
  const [inputValueSetter, setInputValueSetter] = useState<string>('');

  useEffect(() => {
    inputValue && setInputValueSetter(inputValue);
  }, [inputValue]);
  return (
    <label className={`${type === 'checkbox' ? 'checkbox' : 'textfield'}`}>
      {label && (
        <span className='textfield__label'>{`${label}${
          Boolean(validation?.required) ? ' *' : ''
        }`}</span>
      )}
      <input
        type={type}
        value={inputValue}
        {...register(inputName, validation)}
        className={`${type === 'checkbox' ? '' : 'textfield__input'} ${
          errors && errors[inputName] ? 'error' : ''
        }`}
      />
      {checkboxText && <span className='checked' />}
      {checkboxText && <p dangerouslySetInnerHTML={{ __html: checkboxText }} />}
      {checkboxText && Boolean(validation?.required) && ' *'}
      {errors && errors[inputName] && (
        <p className='textfield error'>{errors[inputName].message}</p>
      )}
    </label>
  );
};

export default Textfield;
