import { useEffect, useRef, useState } from 'react';

import arrow from '../../assets/icons/arrow.svg';
import './index.scss';

type CollapsibleInterface = {
  children?: React.ReactNode;
  title: string;
};

export const Collapsible: React.FC<CollapsibleInterface> = ({
  children,
  title
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [height, setHeight] = useState<any>(0);

  const textContainerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen)
      setHeight(textContainerRef.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <div className='collapsible'>
      <div
        className={`collapsible__title ${isOpen ? 'expanded' : ''}  `}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {title}
      </div>
      <div className={`collapsible__text collapse`} style={{ height }}>
        <div ref={textContainerRef}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
