import React, { useEffect, useState } from 'react';
import './index.scss';
import overlay from '../../assets/images/overlay.png';
import logoHeader1 from '../../assets/images/background1.jpg';
import logoHeader2 from '../../assets/images/background2.jpg';
import logoHeader3 from '../../assets/images/background3.jpg';
import scrollMeTo from '../../helpers/scrollMeTo';
import { isMobile } from 'react-device-detect';

const Header = () => {
  const slides = [logoHeader1, logoHeader2, logoHeader3];
  const [actualSlide, setActualSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnotherSlide();
    }, 4000);
    return () => clearInterval(intervalId);
  }, []);

  const setAnotherSlide = () => {
    setActualSlide((prevstate) => {
      if (prevstate + 1 === slides.length) {
        return 0;
      }
      return prevstate + 1;
    });
  };

  return (
    <header className='header'>
      <div className='container'>
        <div className='header__background-container'>
          <img
            src={overlay}
            alt='overlay'
            className='header__background-overlay'
          />
          {slides.map((slide, index) => (
            <img
              src={slide}
              alt='Dekra'
              className={`header__background-image ${
                index === actualSlide ? 'active' : ''
              }`}
            />
          ))}
        </div>
        <div className='header__wrapper'>
          <h1 className='wrapper__title'>
            Sprawdź stan baterii <br />
            samochodu w dekra
          </h1>
          <ul className='wrapper__list'>
            <li>Planujesz zakup samochodu elektrycznego lub hybrydy?</li>
            <li>
              Chcesz poznać stan baterii w kontekście sprzedaży lub zakupu z
              finansowaniem (leasing)?
            </li>
            <li>
              Zamierzasz sprzedać pojazd elektryczny lub hybrydę i potrzebujesz
              wiarygodnej oceny stanu baterii?
            </li>
          </ul>
          <div className='wrapper__bottom'>
            <button
              className='button'
              onClick={() => {
                scrollMeTo(isMobile ? 'order-pricing-buttons' : 'selector');
              }}
            >
              Zamów test baterii
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
